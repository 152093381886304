import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/atoms/Link/Link';
import Image from 'components/atoms/Image/Image';

const BillboardWrapper = styled.div`
	min-height: 10rem;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		min-height: 12rem;
	}
	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		min-height: 14.5rem;
	}

	${Link} {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

const StyledDescription = styled.span`
	color: initial;
	background-color: ${({ theme }) => theme.info};
	padding: 0.1rem 0.5rem;
	text-transform: uppercase;
	font-size: 0.8rem;
`;

const StyledImage = styled(Image)`
	border: 1px solid ${({ theme }) => theme.grey300};
`;

const Billboard = ({ url, target, file, description }) => (
	<BillboardWrapper>
		<Link href={url} target={target} rel="noreferrer">
			{description && <StyledDescription>{description}</StyledDescription>}
			<StyledImage src={file} alt="billboard" />
		</Link>
	</BillboardWrapper>
);

Billboard.propTypes = {
	url: PropTypes.string.isRequired,
	file: PropTypes.string.isRequired,
	target: PropTypes.string,
	description: PropTypes.string,
};

Billboard.defaultProps = {
	description: '',
	target: '_blank',
};

export default Billboard;
