import React from 'react';
import styled from 'styled-components';
import Link from '../../atoms/Link/Link';

const DropdownButton = styled.a`
	color: rgb(0, 0, 0);
	border: none;
	background: transparent;
`;

const DropdownMenu = styled.ul`
	margin-top: 0;
	background-color: hsl(0, 0%, 96%);
	border: none;
	border-radius: 0;
	border-bottom: 1px solid hsl(0, 0%, 90%);
	li.nav-link {
		padding: 0;
		a.nav-link {
			padding-bottom: 6px;
			padding-top: 6px;
		}
	}
	.dropdown-menu[data-bs-popper] {
		top: 125%;
	}
	li > a.dropdown-item.active,
	li > a.dropdown-item:active {
		background-color: #be1a8d;
		color: #fff;
	}
`;
const Dropdown = ({ target, label, items, darkmode, secondtheme }) => (
	<div className="dropdown">
		<DropdownButton
			id={target}
			className="nav-link dropdown-toggle"
			role="button"
			data-bs-toggle="dropdown"
			aria-expanded="false"
		>
			{label}
		</DropdownButton>
		<DropdownMenu className="dropdown-menu" aria-labelledby={target}>
			{/* eslint-disable-next-line no-shadow */}
			{items &&
				items.map(({ url, name }, index) => (
					<li className="nav-link" key={`dropdown_item_${index}`}>
						<Link
							href={url}
							target="_blank"
							rel="noreferrer"
							darkmode={darkmode}
							secondtheme={secondtheme}
							className="dropdown-item nav-link"
						>
							{name}
						</Link>
					</li>
				))}
		</DropdownMenu>
	</div>
);

export default Dropdown;
