import styled, { css } from 'styled-components';

const UnorderedList = styled.ul`
	list-style: none;
	padding-left: 0;

	${({ horizontal }) =>
		horizontal &&
		css`
			display: flex;
			flex-wrap: wrap;

			li {
				margin-right: 0.5rem;
				&:last-child {
					margin-right: 0;
				}
			}
		`};
`;

export default UnorderedList;
