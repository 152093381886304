const Theme = () => {
	const theme = {
		primary: '#d22027',
		secondary: '#226fc7',
		info: '#ebc40e',
		grey100: 'hsl(0, 0%, 96%)',
		grey200: 'hsl(0, 0%, 90%)',
		grey300: 'hsl(0, 0%, 70%)',
		grey400: 'hsl(0, 0%, 60%)',
		grey500: 'hsl(0, 0%, 50%)',
		grey600: 'hsl(0, 0%, 40%)',
		grey700: 'hsl(0, 0%, 30%)',
		grey800: 'hsl(0, 0%, 20%)',
		grey900: 'hsl(0, 0%, 10%)',
		black: 'hsl(0, 0%, 0%)',
		light: 400,
		bold: 600,
		xbold: 700,
		breakpoints: {
			xs: '0',
			sm: '576px',
			md: '768px',
			lg: '992px',
			xl: '1200px',
			xxl: '1400px',
		},
	};

	if (process.env.REACT_APP_THEME === 'LICEA') {
		theme.primary = '#be1a8d';
		theme.secondary = '#4527a0';
		theme.info = '#ebc40e';
	}

	if (process.env.REACT_APP_THEME === 'TECHNIKA') {
		theme.primary = '#4527a0';
		theme.secondary = '#be1a8d';
		theme.info = '#ebc40e';
	}

	return theme;
};

export default Theme;
