import React from 'react';
import styled from 'styled-components';
import ErrorInitImg from 'assets/img/error-init.jpg';

const StyledWrapper = styled.div`
	text-align: center;
	background-color: #111111;
	min-height: 100vh;
`;

const ErrorAlert = ({ error }) => (
	<StyledWrapper>
		<img src={ErrorInitImg} className="img-fluid" alt={`${process.env.REACT_APP_TITLE} - ${error}`} />
	</StyledWrapper>
);

export default ErrorAlert;
