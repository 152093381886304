const initialState = {
	items: [],
	articlesGroup: {},
};

const articles = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_ARTICLE_SUCESS':
			return {
				...state,
				items: [...state.items, action.payload],
			};
		case 'FETCH_ARTICLE_GROUP_SUCESS':
			return {
				...state,
				articlesGroup: {
					...state.articlesGroup,
					[action.payload.hash]: [...action.payload.articles],
				},
			};
		case 'RESET_STATE_SUCESS':
			return initialState;
		default:
			return state;
	}
};

export default articles;
