const initialState = null;

const global = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_INITIAL_STATE_SUCESS':
			return action.payload.version ?? null;
		default:
			return state;
	}
};

export default global;
