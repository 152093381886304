import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['initial'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
	process.env.REACT_APP_ENV === 'development' ? composeWithDevTools(applyMiddleware(thunk)) : applyMiddleware(thunk);
const store = createStore(persistedReducer, composeEnhancers);

const persistor = persistStore(store);

export { store, persistor };
