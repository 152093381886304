export const routes = {
	home: '/',
	articles: '/articles',
	article: '/articles/:slug',
	rankings: '/rankings',
	ranking: '/rankings/:slug',
	methodologies: '/methodology',
	methodology: '/methodology/:slug',
	prezentacje: '/prezentacje',
	rankingJury: '/ranking-jury',
	qualityMark: '/quality-mark',
	gallery: '/gallery',
	errata: '/errata',
	school: '/school/:slug',
};
