import React from 'react';

const ErrorAlert = ({ error }) => (
	<div className="alert alert-danger" role="alert">
		<h4 className="alert-heading">Coś poszło nie tak:</h4>
		{error.message && <p className="mb-0">{error.message}</p>}
	</div>
);

export default ErrorAlert;
