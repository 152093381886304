const initialState = null;

const articles = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_GALLERY_SUCESS':
			return [...action.payload];
		case 'RESET_STATE_SUCESS':
			return initialState;
		default:
			return state;
	}
};

export default articles;
