import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.button`
	position: relative;
	cursor: pointer;
	background-color: transparent;
	margin: 0.75rem;
	margin-left: auto;
	width: 2.2rem;
	height: 1.5rem;
	border: none;
	transform: rotate(0);
	transition: 0.5s ease-in-out;

	:focus {
		outline: none;
		box-shadow: none;
	}

	span {
		display: block;
		position: absolute;
		height: 0.2rem;
		width: 100%;
		background: ${({ dark, theme }) => (dark ? '#000' : theme.grey200)};
		opacity: 1;
		left: 0;
		transform: rotate(0);
		transition: 0.25s ease-in-out;

		&:nth-child(1) {
			top: 0;
		}
		&:nth-child(2),
		&:nth-child(3) {
			top: 0.625rem;
		}
		&:nth-child(4) {
			top: 1.25rem;
		}
	}

	&[aria-expanded='true'] span {
		&:nth-child(1),
		&:nth-child(4) {
			top: 0.625rem;
			width: 0%;
			left: 50%;
		}
		&:nth-child(2) {
			transform: rotate(45deg);
		}
		&:nth-child(3) {
			transform: rotate(-45deg);
		}
	}
`;

const Burger = ({ target, expanded, dark }) => (
	<StyledWrapper
		className="navbar-toggler"
		type="button"
		data-bs-toggle="collapse"
		data-bs-target={`#${target}`}
		aria-controls={target}
		aria-expanded={expanded ?? 'false'}
		aria-label="Toggle navigation"
		dark={dark}
	>
		<span />
		<span />
		<span />
		<span />
	</StyledWrapper>
);

export default Burger;
