import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
// COMPONENTS
import Billboard from 'components/molecules/Billboard/Billboard';

const StyledWrapper = styled.div`
	background-color: ${({ theme }) => theme.grey100};
`;

const Billboards = ({ billboards }) => {
	const { pathname } = useLocation();
	const [activeBillboard, setActiveBillboard] = useState();

	useEffect(() => {
		const billboardIndex = Math.floor(Math.random() * billboards.length);
		setActiveBillboard(billboardIndex);
	}, [pathname]);

	return (
		<>
			{billboards[activeBillboard] && (
				<StyledWrapper className="container-fluid">
					<Billboard {...billboards[activeBillboard]} />
				</StyledWrapper>
			)}
		</>
	);
};
const mapStateToProps = ({ banners }) => ({ billboards: banners.billboards ?? [] });

export default connect(mapStateToProps)(Billboards);
