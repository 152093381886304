import React from 'react';
import PropTypes from 'prop-types';

const containerValue = {
	sm: 'container-sm',
	md: 'container-md',
	lg: 'container-lg',
	xl: 'container-xl',
	xxl: 'container-xxl',
	fluid: '',
};

const ContainerTemplate = ({ children, expand }) => (
	<div className={`container-fluid ${containerValue[expand]}`}>{children}</div>
);

ContainerTemplate.propTypes = {
	children: PropTypes.node.isRequired,
	expand: PropTypes.string,
};
ContainerTemplate.defaultProps = {
	expand: 'xl',
};

export default ContainerTemplate;
