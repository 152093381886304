const initialState = {
	global: null,
	ranking: {},
};

const banners = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_RANKING_BANNERS_SUCESS':
			return {
				...state,
				ranking: {
					...state.ranking,
					[action.payload.id]: action.payload.data,
				},
			};
		case 'FETCH_BANNERS_SUCESS':
			return {
				...state,
				[action.payload.id]: action.payload.data,
			};
		case 'RESET_STATE_SUCESS':
			return initialState;
		default:
			return state;
	}
};

export default banners;
