import axios from 'axios';
import { fetchBannersSuccess } from '../actions/fetch';

export const operationFetchBanners = ({ hash, id }) => async (dispatch) => {
	const endpoint = `${process.env.REACT_APP_API}/banners/${hash}`;

	axios
		.get(endpoint, {
			accept: 'application/json',
		})
		.then((response) => {
			dispatch(fetchBannersSuccess({ data: response.data, id }));
		})
		.catch((error) => {
			let errorData = 'error';
			if (error.response && error.response.data && error.response.data.message) {
				errorData = error.response.data.message;
			}
			// eslint-disable-next-line
			console.log(errorData);
		});
};
