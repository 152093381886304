import axios from 'axios';
import { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';

const dataFetchReducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_INIT':
			return { ...state, isLoading: true, isError: false };
		case 'FETCH_SUCCESS':
			return { ...state, isLoading: false, isError: false };
		case 'FETCH_FAILURE':
			return { ...state, isLoading: false, isError: true, error: action.payload };
		default:
			throw new Error();
	}
};

const useFetch = ({ endpoint, action }) => {
	const [state, dispatchReducer] = useReducer(dataFetchReducer, { isLoading: false, isError: false });
	const [isInitiated, setIsInitiated] = useState();
	const dispatch = useDispatch();
	useEffect(() => {
		let didCancel = false;
		if (isInitiated) {
			const fetchData = async () => {
				dispatchReducer({ type: 'FETCH_INIT' });
				try {
					await axios
						.get(endpoint, {
							accept: 'application/json',
						})
						.then((response) => {
							dispatchReducer({ type: 'FETCH_SUCCESS' });
							dispatch(action(response.data));
							setIsInitiated();
						})
						.catch((error) => {
							if (!didCancel) {
								let errorData = 'error';
								if (error.response && error.response.data && error.response.data.message) {
									errorData = error.response.data.message;
								}
								dispatchReducer({ type: 'FETCH_FAILURE', payload: errorData });
								setIsInitiated();
							}
						});
				} catch (error) {
					if (!didCancel) {
						dispatchReducer({ type: 'FETCH_FAILURE', payload: 'error' });
						setIsInitiated();
					}
				}
			};
			fetchData();
		}
		return () => {
			didCancel = true;
		};
	}, [isInitiated]);

	const fetchInit = () => {
		setIsInitiated(true);
	};

	return [state, fetchInit];
};
export default useFetch;
