import React, { useEffect, Suspense } from 'react';
import MainTemplate from 'templates/MainTemplate';
// ROUTER
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { routes } from 'routes';
import AppRedirect from 'routes/Redirect';
import { ScrollToTop } from 'functions/scrollToTop';
// REDUX
import useInit from 'useHandlers/useInit';
import { connect } from 'react-redux';
// BOUNDARY
import ErrorInit from 'boundary/ErrorInit';
// COMPONETS
import Loader from 'components/atoms/Loader/Loader';
// ASETES:
import 'assets/fonts/fontawesome';
import 'assets/sass/theme.scss';
import 'assets/js/bootstrap';

// VIEWS
const Articles = React.lazy(() => import('views/Articles'));
const Article = React.lazy(() => import('views/Article'));
const Rankings = React.lazy(() => import('views/Rankings'));
const Ranking = React.lazy(() => import('views/Ranking'));
const Methodology = React.lazy(() => import('views/Methodology'));
const QualityMark = React.lazy(() => import('views/QualityMark'));
const Prezentacje = React.lazy(() => import('views/Prezentacje'));
const Gallery = React.lazy(() => import('views/Gallery'));
const Errata = React.lazy(() => import('views/Errata'));
const School = React.lazy(() => import('views/School'));

const App = ({ articles, name }) => {
	const [{ isLoading, isError, error }, fetchInit] = useInit();

	useEffect(() => {
		fetchInit();
	}, []);

	return (
		<Router basename={process.env.REACT_APP_BASENAME ?? null}>
			<ScrollToTop />
			{isLoading && <Loader />}
			{name && (
				<MainTemplate>
					<main>
						<Suspense fallback={<Loader />}>
							<Switch>
								<Route exact path={routes.home} component={AppRedirect} />
								<Route exact path={routes.articles} component={Articles} />
								<Route exact path={[routes.article, routes.methodology]} component={Article} />
								<Route exact path={routes.rankings} component={Rankings} />
								<Route exact path={routes.ranking} component={Ranking} />
								<Route exact path={routes.school} component={School} />
								<Route exact path={routes.methodologies} component={Methodology} />
								<Route exact path={routes.prezentacje} component={Prezentacje} />
								<Route exact path={routes.qualityMark} component={QualityMark} />
								<Route exact path={routes.gallery} component={Gallery} />
								<Route exact path={routes.errata} component={Errata} />
								<Route
									exact
									path={routes.rankingJury}
									component={() => <Article propSlug={articles['ranking-jury'] ?? 'notHash'} />}
								/>
								<Route component={AppRedirect} />
							</Switch>
						</Suspense>
					</main>
				</MainTemplate>
			)}
			{isError && <ErrorInit error={{ message: error }} />}
		</Router>
	);
};

const mapStateToProps = ({ initial }) => ({
	articles: initial.articles,
	name: initial.name,
});

export default connect(mapStateToProps)(App);
