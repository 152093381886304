import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'components/atoms/Link/Link';
import UnorderedList from 'components/atoms/UnorderedList/UnorderedList';

const AccordionListWrapper = styled.div`
	@media (max-width: ${({ expand, theme }) => theme.breakpoints[expand]}) {
		border-bottom: ${({ borderBottom, theme }) => (borderBottom ? `1px solid ${theme.grey400}` : '')};
		border-top: ${({ borderTop, theme }) => (borderTop ? `1px solid ${theme.grey400}` : '')};
	}

	${UnorderedList} {
		overflow: hidden;
		font-size: 0.95rem;

		@media (max-width: ${({ expand, theme }) => theme.breakpoints[expand]}) {
			line-height: ${({ expanded }) => (expanded ? '' : '0')};
			margin-bottom: ${({ expanded }) => (expanded ? '0.75rem' : '0')};
			transition: all 0.4s ease;

			${Link},
			.listItem {
				padding: ${({ expanded }) => (expanded ? '0.35rem 1rem' : '0 1rem')};
				width: 100%;
			}
		}
		@media (min-width: ${({ expand, theme }) => theme.breakpoints[expand]}) {
			line-height: 1.1rem;
			${Link},
			.listItem {
				margin-bottom: 0.35rem;
			}
		}

		${Link},
		.listItem {
			display: inline-block;
			color: ${({ theme }) => theme.grey600};
		}

		${Link} {
			&:hover {
				color: ${({ theme }) => theme.primary};
			}
		}
	}
`;

const CopyrightsTitle = styled.div`
	display: flex;
	align-items: center;
	font-size: 1rem;
	font-weight: ${({ theme }) => theme.bold};
	margin-bottom: 0;

	@media (max-width: ${({ expand, theme }) => theme.breakpoints[expand]}) {
		user-select: none;
		cursor: pointer;
		padding: 0.75rem 0;
	}

	@media (min-width: ${({ expand, theme }) => theme.breakpoints[expand]}) {
		margin-bottom: 0.25rem;
	}
`;

const FontAwesomeStyled = styled(FontAwesomeIcon)`
	color: ${({ theme }) => theme.grey600};
	font-size: 1.35rem;
	margin-right: 0.5rem;
	margin-left: auto;
	transition: transform 0.4s ease;

	@media (min-width: ${({ expand, theme }) => theme.breakpoints[expand]}) {
		display: none;
	}
`;

const AccordionList = ({ title, data, expand, borderBottom, borderTop }) => {
	const [isActive, setIsActive] = useState(false);

	return (
		<AccordionListWrapper expanded={isActive} expand={expand} borderBottom={borderBottom} borderTop={borderTop}>
			<CopyrightsTitle expand={expand} onClick={() => setIsActive(!isActive)}>
				{title}
				<FontAwesomeStyled icon={['fal', 'chevron-down']} rotation={isActive ? 180 : 0} expand={expand} />
			</CopyrightsTitle>
			<UnorderedList>
				{data &&
					data.map((item, index) => (
						<li key={index}>
							{item.url ? (
								<Link href={item.url} target={item.target ?? '_self'} rel="noreferrer">
									{item.name}
								</Link>
							) : (
								<span className="listItem">{item.name}</span>
							)}
						</li>
					))}
			</UnorderedList>
		</AccordionListWrapper>
	);
};

AccordionList.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.array.isRequired,
	expand: PropTypes.string,
	borderBottom: PropTypes.bool,
	borderTop: PropTypes.bool,
};

AccordionList.defaultProps = {
	expand: 'sm',
	borderBottom: false,
	borderTop: false,
};

export default AccordionList;
