import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledWrapper = styled.img`
	${({ pointer }) =>
		pointer &&
		css`
			cursor: pointer;
		`}
`;

const Image = ({ className, src, alt, onClick, pointer }) => (
	<StyledWrapper
		className={`img-fluid ${className}`}
		src={src}
		alt={alt}
		onClick={onClick}
		pointer={pointer ? 1 : undefined}
	/>
);

Image.propTypes = {
	src: PropTypes.string.isRequired,
	className: PropTypes.string,
	alt: PropTypes.string,
	onClick: PropTypes.func,
	pointer: PropTypes.bool,
};

Image.defaultProps = {
	className: '',
	alt: '',
	onClick: () => {},
	pointer: false,
};

export default Image;
