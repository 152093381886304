import { routes } from 'routes';

const initialState = {
	shield: `https://i.perspektywy.pl/rankings/licea/${process.env.REACT_APP_EDITION}/tarcza_${process.env.REACT_APP_EDITION}.png`,
	footer: null,
	copyrights: null,
	navBar: {
		initial: true,
		items: [
			{
				id: 1,
				name: 'Informacje',
				url: routes.articles,
				blank: false,
			},
			{
				id: 2,
				name: 'Rankingi',
				url: routes.rankings,
				blank: false,
			},
			{
				id: 3,
				name: 'Metodologia',
				url: routes.methodologies,
				blank: false,
			},
			{
				id: 4,
				name: 'Kapitula rankingu',
				url: routes.rankingJury,
				blank: false,
			},
			{
				id: 5,
				name: 'Znak jakosci',
				url: routes.qualityMark,
				blank: false,
			},
		],
	},
	voivodeships: [
		{
			title: 'dolnośląski',
			link: `${routes.rankings}/ranking-dolnoslaski`,
		},
		{
			title: 'kujawsko-pomorski',
			link: `${routes.rankings}/ranking-kujawsko-pomorski`,
		},
		{
			title: 'lubelski',
			link: `${routes.rankings}/ranking-lubelski`,
		},
		{
			title: 'lubuski',
			link: `${routes.rankings}/ranking-lubuski`,
		},
		{
			title: 'łódzki',
			link: `${routes.rankings}/ranking-lodzki`,
		},
		{
			title: 'małopolski',
			link: `${routes.rankings}/ranking-malopolski`,
		},
		{
			title: 'mazowiecki',
			link: `${routes.rankings}/ranking-mazowiecki`,
		},
		{
			title: 'opolski',
			link: `${routes.rankings}/ranking-opolski`,
		},
		{
			title: 'podkarpacki',
			link: `${routes.rankings}/ranking-podkarpacki`,
		},
		{
			title: 'podlaski',
			link: `${routes.rankings}/ranking-podlaski`,
		},
		{
			title: 'pomorski',
			link: `${routes.rankings}/ranking-pomorski`,
		},
		{
			title: 'śląski',
			link: `${routes.rankings}/ranking-slaski`,
		},
		{
			title: 'świętokrzyski',
			link: `${routes.rankings}/ranking-swietokrzyski`,
		},
		{
			title: 'warmińsko-mazurski',
			link: `${routes.rankings}/ranking-warminsko-mazurski`,
		},
		{
			title: 'wielkopolski',
			link: `${routes.rankings}/ranking-wielkopolski`,
		},
		{
			title: 'zachodniopomorski',
			link: `${routes.rankings}/ranking-zachodniopomorski`,
		},
	],
};

const global = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_MAIN_MENU_SUCESS':
			return {
				...state,
				navBar: {
					initial: false,
					items: [...action.payload.items],
				},
			};
		case 'FETCH_FOOTER_SUCESS':
			return {
				...state,
				footer: [...action.payload.items],
			};
		case 'FETCH_COPYRIGHTS_SUCESS':
			return {
				...state,
				copyrights: [...action.payload.items],
			};
		case 'RESET_STATE_SUCESS':
			return initialState;
		default:
			return state;
	}
};

export default global;
