import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [pathname]);

	return null;
};

export const ScrollToTopOnMount = () => {
	useEffect(() => {
		document.getElementById('subheader').scrollIntoView({
			behavior: 'smooth',
		});
	}, []);

	return null;
};
