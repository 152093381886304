const initialState = {};

const rankings = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_RANKING_SUCESS':
			return {
				...state,
				[action.payload.slug]: action.payload.data,
			};
		case 'RESET_STATE_SUCESS':
			return initialState;
		default:
			return state;
	}
};

export default rankings;
