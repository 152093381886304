import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContainerTemplate from 'templates/ContainerTemplate';
// REDUX
import useFetch from 'useHandlers/useFetch';
import { connect } from 'react-redux';
import { fetchFooterSuccess } from 'redux/actions/fetch';
// BOUNDARY
import { ErrorBoundary } from 'react-error-boundary';
// COMPONENTS
import Copyrights from 'components/molecules/Copyrights/Copyrights';
import AccordionList from 'components/molecules/AccordionList/AccordionList';

const FooterWrapper = styled.div`
	background-color: ${({ theme }) => theme.grey100};
	padding-top: 2rem;
`;

const Footer = ({ expand, footer }) => {
	const endpoint = `${process.env.REACT_APP_API}/menu/rpqjtwatl3`;
	const [{ error }, fetchInit] = useFetch({ endpoint, action: fetchFooterSuccess });
	useEffect(() => {
		if (!footer) {
			fetchInit();
		}
	}, []);
	useEffect(() => {
		if (error) {
			// eslint-disable-next-line
			console.log(error);
		}
	}, [error]);

	return (
		<ErrorBoundary>
			{footer && (
				<FooterWrapper>
					<ContainerTemplate>
						<div className="row">
							{footer.map((items, index) => (
								<div className={`col-${expand}-4`} key={index}>
									<AccordionList title={items.name} data={items.subItems} expand={expand} borderTop />
								</div>
							))}
						</div>
					</ContainerTemplate>
				</FooterWrapper>
			)}
			<Copyrights />
		</ErrorBoundary>
	);
};

Footer.propTypes = {
	expand: PropTypes.string,
};

Footer.defaultProps = {
	expand: 'md',
};

const mapStateToProps = ({ global }) => ({ footer: global.footer });

export default connect(mapStateToProps)(Footer);
