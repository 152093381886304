import styled, { css } from 'styled-components';

const Link = styled.a`
	color: ${({ darkmode }) => (darkmode ? '#fff' : 'initial')};
	text-decoration: none;

	&:hover {
		color: ${({ secondtheme, theme }) => (secondtheme ? theme.secondary : theme.primary)};
	}

	${({ featured }) =>
		featured &&
		css`
			color: ${({ secondtheme, theme }) => (secondtheme ? theme.secondary : theme.primary)};
			font-weight: ${({ theme }) => theme.bold};
			:hover {
				text-decoration: underline;
			}
		`}

	${({ more }) =>
		more &&
		css`
			font-size: 0.8rem;
			display: block;
			text-align: end;
		`}

	${({ btn }) =>
		btn &&
		css`
			padding: 0.25rem 0.5rem;
			font-size: 0.875rem;
			border-radius: 0.2rem;
			color: ${({ theme }) => theme.primary};
			background-color: #fff;
			border: 1px solid ${({ theme }) => theme.primary};

			:hover {
				color: #fff;
				background-color: ${({ theme }) => theme.primary};
				border-color: ${({ theme }) => theme.primary};
			}
		`}

	${({ colorWhite }) =>
		colorWhite &&
		css`
			color: #fff;
			:hover {
				color: #fff;
				text-decoration: underline;
			}
		`}
`;

export default Link;
