import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import ContainerTemplate from 'templates/ContainerTemplate';
// REDUX
import useFetch from 'useHandlers/useFetch';
import { connect } from 'react-redux';
import { fetchMainMenuSuccess } from 'redux/actions/fetch';
// BOUNDARY
import { ErrorBoundary } from 'react-error-boundary';
import ErrorAlert from 'boundary/ErrorAlert';
// COMPONENTS
import Image from 'components/atoms/Image/Image';
import Link from 'components/atoms/Link/Link';
import Navbar from 'components/molecules/Navbar/Navbar';
import LogoPerspektywy from 'assets/img/perspektywy_logo_fff.png';

const HeaderWrapper = styled.div`
	background-color: ${({ secondtheme, theme }) => (secondtheme ? theme.secondary : theme.primary)};
`;

const HeaderTop = styled.div`
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		flex-direction: column;
		justify-content: center;
	}
`;

const HeadingWrapper = styled(RouterLink)`
	color: #fff;
	text-decoration: none;
	font-size: 3.5rem;
	line-height: 3.5rem;
	text-transform: lowercase;

	:first-letter {
		text-transform: uppercase;
	}
	:hover {
		color: #fff;
	}

	span {
		font-weight: ${({ theme }) => theme.bold};
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-top: 1rem;
		font-size: 12.5vw;
	}
`;

const Header = ({ navBar, menus, secondtheme }) => {
	const endpoint = `${process.env.REACT_APP_API}/menu/${menus['main-menu']}`;
	const [{ error }, fetchInit] = useFetch({ endpoint, action: fetchMainMenuSuccess });

	useEffect(() => {
		if (navBar.initial) {
			fetchInit();
		}
	}, [menus]);

	useEffect(() => {
		if (error) {
			// eslint-disable-next-line
			console.log(error);
		}
	}, [error]);

	return (
		<HeaderWrapper secondtheme={secondtheme}>
			<ContainerTemplate>
				<HeaderTop>
					<Link href="http://www.perspektywy.pl/portal/" target="_blank" rel="noreferrer">
						<Image src={LogoPerspektywy} alt="Perspektywy" />
					</Link>
					<HeadingWrapper to="/">
						{process.env.REACT_APP_THEME}
						<span>&nbsp;{process.env.REACT_APP_EDITION}</span>
					</HeadingWrapper>
				</HeaderTop>
			</ContainerTemplate>
			<ErrorBoundary FallbackComponent={ErrorAlert}>
				<Navbar id="mainNav" content={navBar.items} navAside expand="md" secondtheme={secondtheme} />
			</ErrorBoundary>
		</HeaderWrapper>
	);
};
const mapStateToProps = ({ initial, global }) => ({ navBar: global.navBar, menus: initial.menus ?? {} });

export default connect(mapStateToProps)(Header);
