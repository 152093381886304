import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoaderWrapper = styled.div`
	text-align: ${({ align }) => align};
`;

const StyleIcon = styled(FontAwesomeIcon)`
	color: ${({ theme }) => theme.primary};
`;

const Loader = ({ size, align }) => (
	<LoaderWrapper align={align}>
		<StyleIcon icon={['fad', 'spinner']} size={size} pulse />
	</LoaderWrapper>
);

Loader.propTypes = {
	size: PropTypes.string,
	align: PropTypes.string,
};

Loader.defaultProps = {
	size: '3x',
	align: 'center',
};

export default Loader;
