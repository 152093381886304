import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`${css`
	/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap'); --> import inside index.html  */

	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	body,
	html {
		font-family: 'Open Sans', sans-serif;
		margin: 0;
		padding: 0;
	}

	html,
	body,
	#root,
	.App {
		height: 100%;
	}

	.App {
		display: flex;
		flex-direction: column;
		.AppContent {
			flex: 1 0 auto;
		}
		.AppFooter {
			flex-shrink: 0;
		}

		main {
			height: calc(100% - #{$mianNavHeight});
		}
	}
`}`;
