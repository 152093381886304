import { combineReducers } from 'redux';
import version from './version';
import global from './global';
import articles from './articles';
import rankings from './rankings';
import banners from './banners';
import gallery from './gallery';
import initial from './initial';

export default combineReducers({
	version,
	initial,
	global,
	articles,
	rankings,
	banners,
	gallery,
});
