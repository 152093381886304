// How to import fontAwesome icon:
// 1) inside app.js -> import 'assets/fonts/fontawesome';
// 2) in component -> import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// 3) in component -> <FontAwesomeIcon icon={['fal', 'code']} />
// ---------------------------------------------------------------------------------------
// Settings:
// size="sm" || size="2x"
// style={{ color: 'red' }}
// transform="down-4 grow-2.5"
// fixedWidth
// spin
// mask={['fas', 'circle']}

import { library } from '@fortawesome/fontawesome-svg-core';
// brands
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
// light
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
// duotone
import { faUserShield, faSpinner } from '@fortawesome/pro-duotone-svg-icons';
// solid
import {} from '@fortawesome/free-solid-svg-icons';
import {} from '@fortawesome/pro-solid-svg-icons';
// regular
import {} from '@fortawesome/free-regular-svg-icons';
import {} from '@fortawesome/pro-regular-svg-icons';

library.add(faChevronDown, faFacebookF, faUserShield, faSpinner);
